aside.open > .workspace-select-section {
    display: initial;
    margin-left: 10px;
    margin-right: 10px;
}
aside:hover > .workspace-select-section {
    display: initial;
    margin-left: 10px;
    margin-right: 10px;
}

.workspace-select-section {
    display: none;
}

.option-select-title {
    padding-left: 10px;
}