.search-engine-image {
    padding: 0.25rem;
    border: 1px solid #dee2e6;
    border-radius: 1rem;
}

.scrolling-list {
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    position: relative;
}

.list-scroller {}

.list-scroller .outer-list {
    display: inline-block;
}

.image-w-button {
    position: relative;
    display: inline-block;
    width: 150;
    height: 150
}

.image-w-button-overlay {
    position: absolute;
    right: 0;
    z-index: 5;
}